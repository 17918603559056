import React from "react";

import Reveal from "./Reveal";

const Entourage = () => {
  return (
    <>
      <Reveal>
        <div className="text-3xl abhaya-libre-regular md:text-4xl">
          Entourage
        </div>
      </Reveal>
      <Reveal>
        <div className="text-xl abhaya-libre-regular mt-8 uppercase md:text-2xl">
          With the blessings of our parents
        </div>
        <div className="grid grid-cols-2 gap-10 my-4 abhaya-libre-regular entourage-container">
          <div className="text-right text-l md:text-xl">
            <div>Margarito Amante Amada</div>
            <div>Carol Santander Amada</div>
          </div>
          <div className="text-left text-l md:text-xl">
            <div>Gilberto Diño Tutor</div>
            <div>Hilda Caagbay Tutor</div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="text-xl abhaya-libre-regular mt-4 uppercase md:text-2xl">
          Officiating Minister
        </div>
        <div className="text-l abhaya-libre-regular md:text-xl">
          Pastor Jan Elexiz Mercado
        </div>
      </Reveal>
      {/* Principal Sponsors */}
      <Reveal>
        <div className="text-xl abhaya-libre-regular mt-8 uppercase md:text-2xl">
          Principal Sponsors
        </div>
        <div className="grid grid-cols-2 gap-10 my-4 abhaya-libre-regular entourage-container">
          <div className="text-right text-l md:text-xl">
            <div>Mr. Jairo Estipona</div>
            <div>Mr. Rodolfo Fermin Jr.</div>
            <div>Mr. Arnelio Gabin</div>
            <div>Mr. Chedorlaomer Caagbay</div>
            <div>Mr. Edgardo Anoso</div>
          </div>
          <div className="text-left text-l md:text-xl">
            <div>Mrs. Erlinda Estipona</div>
            <div>Mrs. Violeta Cruz</div>
            <div>Mrs. Irelyn Gabin</div>
            <div>Mrs. Merlinia Shiraishi</div>
            <div>Mrs. Janette Placina</div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="text-xl abhaya-libre-regular mt-8 uppercase md:text-2xl">
          Bestman & Maid of Honor
        </div>
        <div className="grid grid-cols-2 gap-10 my-4 abhaya-libre-regular entourage-container">
          <div className="text-right text-l md:text-xl">
            <div>Angelorome Asistin</div>
          </div>
          <div className="text-left text-l md:text-xl">
            <div>Jazel Grace Placina</div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="text-xl abhaya-libre-regular mt-8 uppercase md:text-2xl">
          Secondary Sponsors
        </div>
        <div className="grid grid-cols-2 gap-10 my-4 abhaya-libre-regular entourage-container">
          <div className="text-right text-l md:text-xl">
            <div>Eilldrich Gale Burgo</div>
            <div>Earl Jaison Estipona</div>
            <div>Darcy Jordan Tutor</div>
            <div>Riel Mari Ramirez</div>
            <div>Christian Jaizen Estipona</div>
          </div>
          <div className="text-left text-l md:text-xl">
            <div>Innah Patricia Batocabe</div>
            <div>Arielle Danvic De Leon</div>
            <div>Mariz Montales</div>
            <div>Corwell Charisse Arroyo</div>
            <div>Ma. Angela Madlangbayan</div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="grid grid-cols-1 abhaya-libre-regular mb-4 entourage-container">
          <div className="text-center">
            <div className="text-xl uppercase md:text-2xl mt-4">
              Candle Lighters
            </div>
            <div className="text-l md:text-xl">Kaleb Amada</div>
            <div className="text-l md:text-xl">Eliel Mercado</div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10 mt-4 abhaya-libre-regular entourage-container">
          <div className="text-right">
            <div className="text-xl uppercase md:text-2xl">bible bearer</div>
            <div className="text-l md:text-xl">Amir Kiel Fofue</div>
          </div>
          <div className="text-left">
            <div className="text-xl uppercase md:text-2xl">Flower Girl</div>
            <div className="text-l md:text-xl">Eliah Mercado</div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10 my-4 abhaya-libre-regular entourage-container">
          <div className="text-right">
            <div className="text-xl uppercase md:text-2xl">Covenant Bearer</div>
            <div className="text-l md:text-xl">Lukas Amada</div>
          </div>
          <div className="text-left">
            <div className="text-xl uppercase md:text-2xl">Pen Bearer</div>
            <div className="text-l md:text-xl">Ezekiel Fofue</div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

export default Entourage;
