import React, { useCallback, useState } from "react";
import MosaicCard from "./MosaicCard";
import Reveal from "./Reveal";
import ImageViewer from "react-simple-image-viewer";

const FreaquentlyAsked = (scrollRsvp) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const images = ["./websize/our-haven.jpg"];

  const openImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <section className="hero abhaya-libre-regular text-slate-300 py-8">
      <Reveal>
        <div className="text-3xl text-center mb-4 ">
          Frequently Asked Questions
        </div>
      </Reveal>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <MosaicCard title="What time should I arrive?">
          <div>
            Our ceremony will begin promptly at 3:00 PM so we kindly request
            that you arrive 30 minutes early to assure you don't miss anything.
            This will also give you time to park, sign the guest book, and find
            a seat.
          </div>
        </MosaicCard>
        <MosaicCard title="When do I need to RSVP by?">
          <div>
            Please RSVP by December 5, 2024 by clicking the RSVP button or just
            by scrolling down. If you do not RSVP in time we will assume that
            you are unable to attend.
          </div>
          <button
            className="underline decoration-slate-300 underline-offset-4"
            onClick={scrollRsvp}
          >
            RSVP here
          </button>
        </MosaicCard>
        <MosaicCard title="Will the ceremony be outdoors?">
          <div>The ceremony will be on the garden, if the weather permits.</div>
        </MosaicCard>
        <MosaicCard title="Can I bring a plus one?">
          <div>
            Unfortunately, due to the limited capacity of the venue and the
            number of guests attending, we kindly request that you ONLY bring a
            guest if you were specifically given a plus one on your invitation.
            We hope you understand.
          </div>
        </MosaicCard>
        <MosaicCard title="Where is the wedding venue?">
          <div>The venue is in Ilaya Street, Indang, 4122 Cavite</div>
          <div className="grid grid-cols-1 gap-4 text-center md:grid-cols-3">
            <a
              href="https://waze.com/ul/hwdtbqqypq"
              className="underline decoration-slate-300 underline-offset-4"
              target="_blank"
              rel="noreferrer"
            >
              Click for Waze
            </a>
            <a
              href="https://maps.app.goo.gl/3GTovd486rikz92KA"
              className="underline decoration-slate-300 underline-offset-4"
              target="_blank"
              rel="noreferrer"
            >
              Click for google maps
            </a>
            <button
              className="underline decoration-slate-300 underline-offset-4"
              onClick={openImageViewer}
            >
              Click for pictures
            </button>
          </div>
        </MosaicCard>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0, .7)" }}
        />
      )}
    </section>
  );
};

export default FreaquentlyAsked;
