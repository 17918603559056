import { useState, useEffect, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

const ImageGrid = () => {
  const [images, setImages] = useState([
    "./websize/0RE03098.jpg",
    "./websize/1RE00986.jpg",
    "./websize/0RE02930.jpg",
    "./websize/1RE00206.jpg",
    "./websize/0RE01931-2.jpg",
    "./websize/1RE00169.jpg",
    "./websize/0RE01162.jpg",
    "./websize/1RE09469.jpg",
    "./websize/0RE01342.jpg",
    "./websize/0RE03713.jpg",
    "./websize/0RE03386.jpg",
    "./websize/1RE01498.jpg",
  ]);
  const [isViewerOpen, setIsViewerOpen] = useState("");

  useEffect(() => {
    // Shuffle images array
    const shuffledImages = [...images].sort(() => Math.random() - 0.5);
    setImages(shuffledImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openImageViewer = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [currentImage, setCurrentImage] = useState(null);

  // Split images into groups of 4
  const imageGroups = [];
  for (let i = 0; i < images.length; i += 3) {
    imageGroups.push(images.slice(i, i + 3));
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 m-4">
      {imageGroups.map((group, groupIndex) => (
        <div key={groupIndex} className="grid gap-4">
          {group.map((image, index) => (
            <div
              key={index}
              onClick={() => {
                setCurrentImage([image]);
                openImageViewer();
              }}
            >
              <img
                className="h-full w-full object-cover rounded-lg"
                src={image}
                alt=""
              />
            </div>
          ))}
        </div>
      ))}
      {isViewerOpen && (
        <ImageViewer
          src={currentImage}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0, .7)" }}
        />
      )}
    </div>
  );
};

export default ImageGrid;
