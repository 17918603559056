import React, { useCallback, useState } from "react";

import ImageViewer from "react-simple-image-viewer";

import Reveal from "./Reveal";

const DressCode = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = ["woman-storyboard.png", "male-barong.jpg"];

  const openImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="split-container">
      <div className="details-container">
        <Reveal>
          <div className="text-3xl md:text-4xl abhaya-libre-regular">
            Dress Code
          </div>
          <div className="text-xl md:text-2xl abhaya-libre-regular mt-8 uppercase">
            Strictly Formal
          </div>
          <div className="text-l md:text-xl abhaya-libre-regular">
            No wearing of Black and White
          </div>
          <div className="flex gap-4 items-center justify-center my-4">
            <div className="rounded-full w-10 h-10 border-4 bg-[#D98D6F] border-[#D98D6F]"></div>
            <div className="rounded-full w-10 h-10 border-4 bg-[#EBCA79] border-[#EBCA79]"></div>
            <div className="rounded-full w-10 h-10 border-4 bg-[#B490B0] border-[#B490B0]"></div>
            <div className="rounded-full w-10 h-10 border-4 bg-[#8C9D85] border-[#8C9D85]"></div>
            <div className="rounded-full w-10 h-10 border-4 bg-[#88AAC8] border-[#88AAC8]"></div>
            <div className="rounded-full w-10 h-10 border-4 bg-[#DA969F] border-[#DA969F]"></div>
          </div>
        </Reveal>
        <Reveal>
          <div className="text-xl md:text-2xl abhaya-libre-regular mt-6 uppercase">
            Ladies
          </div>
          <ul className="text-l md:text-xl my-2 abhaya-libre-regular list-none">
            <li>Plain-colored gown</li>
            <li>Gown with floral or pattern prints</li>
          </ul>
        </Reveal>
        <Reveal>
          <div className="text-xl md:text-2xl abhaya-libre-regular mt-4 uppercase">
            Gentlemen
          </div>
          <div className="text-l md:text-xl my-2 abhaya-libre-regular list-none">
            <li>Long-sleeved barong</li>
            <li>Black pants</li>
            <li>Formal shoes</li>
          </div>
        </Reveal>
        <Reveal>
          <button
            className="underline decoration-slate-800 underline-offset-4 abhaya-libre-regular text-l md:text-xl"
            onClick={openImageViewer}
          >
            Click for reference
          </button>
        </Reveal>
      </div>
      <div className="dresscode-background" />
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ backgroundColor: "rgba(0,0,0, .7)" }}
        />
      )}
    </div>
  );
};

export default DressCode;
