import React from "react";

import Reveal from "./Reveal";

const MosaicCard = ({ title, children }) => {
  return (
    <Reveal>
      <div className="text-center m-4 p-4 border-2 min-h-40 text-slate-200">
        <div className="text-xl mb-4 opacity-100">{title}</div>
        {children}
      </div>
    </Reveal>
  );
};

export default MosaicCard;
