import React, { useRef } from "react";

import Reveal from "../components/Reveal";
import Divider from "../components/Divider";
import Entourage from "../components/Entourage";
import DressCode from "../components/DressCode";
import Reservation from "../components/Reservation";
import Timeline from "../components/Timeline";
import FreaquentlyAsked from "../components/FreaquentlyAsked";

import ImageGrid from "../components/ImageGrid";

const Home = () => {
  const rsvpRef = useRef(null);
  const dressCodeRef = useRef(null);

  const scrollRsvp = () => {
    rsvpRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="hero-image">
        <div className="flex flex-col	items-center justify-around h-full gap-52">
          <span className="text-2xl tracking-[.3em] abhaya-libre-regular uppercase text-gray-950 md:text-4xl">
            Karlos & Edelweiss
          </span>
          <Reveal duration={1.5}>
            <button
              className="text-gray-950 border-2 py-3 px-8 border-gray-950 hover:text-white hover:bg-gray-950 abhaya-libre-regular text-l md:text-2xl"
              onClick={scrollRsvp}
            >
              RSVP
            </button>
          </Reveal>
        </div>
      </div>
      <Divider />
      <div className="split-container">
        <div className="details-container">
          <Reveal>
            <div className="flex items-center flex-col text-l abhaya-libre-regular md:text-2xl">
              Join our celebration of love
            </div>
          </Reveal>
          <Reveal>
            <div className="flex items-center flex-col text-l abhaya-libre-regular md:text-xl">
              <p className="text-center px-12 my-12 md:px-5">
                After 13 years together, we've decided to tie the knot and would
                be delighted to have you join us as we say 'I do!
              </p>

              <p className="text-center px-8 my-2">
                {`We will exchange our wedding vows on the 5th of January 2025, three
                o’clock in the afternoon in `}
                <span className="font-bold">
                  Our Haven Events Place, Ilaya Street, Indang Cavite 4122
                </span>
              </p>
            </div>
          </Reveal>
        </div>
        <div className="test-background" />
      </div>
      <Divider />
      <section className="flex flex-col text-center mt-0 md:mt-10">
        <Entourage />
        <ImageGrid />
        <Divider />
        <div ref={dressCodeRef}>
          <DressCode />
        </div>
      </section>
      <section className="flex flex-col text-center my-8 m-4 py-10 abhaya-libre-regular">
        <div className="split-container">
          <div className="timeline-background hidden md:block" />
          <div className="details-container flex flex-col gap-4">
            <Timeline />
            <div className="flex justify-center m-4 hidden md:flex">
              <img
                src="./edelweiss-watercolor.png"
                alt="watercolor"
                className="h-24"
              />
            </div>
            <div className="hidden md:block">
              <Reveal>
                <div className="text-3xl abhaya-libre-regular text-center md:text-4xl">
                  A Note on Gifts
                </div>
              </Reveal>
              <Reveal>
                <p className="text-l abhaya-libre-regular text-center p-4 md:text-xl">
                  With everything we’ve been blessed with, your presence and
                  prayers are all we ask for. However, if you still wish to
                  give, a <span className="font-bold">monetary gift </span>would
                  be graciously appreciated.
                </p>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
      <div className="timeline-background block md:hidden" />
      <section className="grid grid-col-2 pt-4 my-8 md:hidden">
        <Reveal>
          <div className="text-3xl abhaya-libre-regular text-center">
            A Note on Gifts
          </div>
        </Reveal>
        <Reveal>
          <p className="text-l abhaya-libre-regular text-center p-4">
            With everything we’ve been blessed with, your presence and prayers
            are all we ask for. However, if you still wish to give, a{" "}
            <span className="font-bold">monetary gift </span>would be graciously
            appreciated.
          </p>
        </Reveal>
      </section>
      <FreaquentlyAsked scrollRsvp={scrollRsvp} />
      {/* <section className="hero abhaya-libre-regular text-slate-300 py-8">
        <Reveal>
          <div className="text-3xl text-center mb-4 ">
            Frequently Asked Questions
          </div>
        </Reveal>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <MosaicCard title="What time should I arrive?">
            <div>
              Our ceremony will begin promptly at 3:00 PM so we kindly request
              that you arrive 30 minutes early to assure you don't miss
              anything. This will also give you time to park, sign the guest
              book, and find a seat.
            </div>
          </MosaicCard>
          <MosaicCard title="When do I need to RSVP by?">
            <div>
              Please RSVP by December 5, 2024 by clicking the RSVP button or
              just by scrolling down. If you do not RSVP in time we will assume
              that you are unable to attend.
            </div>
            <button
              className="underline decoration-slate-300 underline-offset-4"
              onClick={scrollRsvp}
            >
              RSVP here
            </button>
          </MosaicCard>
          <MosaicCard title="Will the ceremony be outdoors?">
            <div>
              The ceremony will be on the garden, if the weather permits.
            </div>
          </MosaicCard>
          <MosaicCard title="Can I bring a plus one?">
            <div>
              Unfortunately, due to the limited capacity of the venue and the
              number of guests attending, we kindly request that you ONLY bring
              a guest if you were specifically given a plus one on your
              invitation. We hope you understand.
            </div>
          </MosaicCard>
          <MosaicCard title="Where is the wedding venue?">
            <div>The venue is in Ilaya Street, Indang, 4122 Cavite</div>
            <a
              href="https://waze.com/ul/hwdtbqqypq"
              className="underline decoration-slate-300 underline-offset-4"
              target="_blank"
              rel="noreferrer"
            >
              Click for Waze
            </a>
            <a
              href="https://maps.app.goo.gl/3GTovd486rikz92KA"
              className="underline decoration-slate-300 underline-offset-4 ml-8"
              target="_blank"
              rel="noreferrer"
            >
              Click for google maps
            </a>
            <button
              className="underline decoration-slate-300 underline-offset-4 ml-8"
              on
            >
              Click for pictures
            </button>
          </MosaicCard>
        </div>
      </section> */}
      <section className="flex flex-col items-center mt-8 mb-4 text-center">
        <Reveal>
          <div className="text-3xl abhaya-libre-regular">
            Unplugged Ceremony
          </div>
        </Reveal>
        <Reveal>
          <div className="text-l abhaya-libre-regular p-3 md:max-w-2xl md:text-xl">
            Please be present at the ceremony without using your phone and
            cameras. Enjoy the moment as our guest and let the professionals
            handle it.
          </div>
        </Reveal>
        <Reveal>
          <div className="text-3xl abhaya-libre-regular">Snap & Share</div>
        </Reveal>
        <Reveal>
          <div className="text-l abhaya-libre-regular p-3 md:max-w-2xl md:text-xl">
            Capture the love after our unplugged ceremony! Share your wedding
            photos using our official wedding hashtag
            #EDELightfulGiftOfGodtoKARLOS
          </div>
        </Reveal>
      </section>
      <section className="flex flex-col text-center" ref={rsvpRef}>
        <Reveal>
          <Reservation />
        </Reveal>
      </section>
      <div className="mb-24"></div>
    </>
  );
};

export default Home;
