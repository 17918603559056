import React, { useEffect, useState } from "react";

import Checkbox from "./Checkbox";
import Reveal from "./Reveal";
import stringSimilarity from "string-similarity";

import { supabase } from "../utils/supabase";
import axios from "axios";

const Reservation = () => {
  const [invitees, setInvitees] = useState([""]);
  const [groupInvite, setGroupInvite] = useState([]);
  const [step, setStep] = useState(1);
  const [ip, setIP] = useState("");
  const updateInvitees = (value, index) => {
    const newInvitees = invitees.map((item, i) => {
      if (i === index) {
        return value;
      }

      return item;
    });

    setInvitees(newInvitees);
  };

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);

  const findGuest = async () => {
    const { data: guests, error } = await supabase.from("invitees").select();
    // .ilike("full_name", invitees[0]);

    if (error) {
      console.error("Error fetching invitees:", error);
      setStep(2);
      return;
    }

    const guestNames = guests.map((guest) => guest.full_name);
    const match = stringSimilarity.findBestMatch(
      invitees[0].toLowerCase(),
      guestNames
    );

    if (match.bestMatch.rating < 0.4) {
      setStep(2);
    } else {
      const tempGuest = guests.find(
        (guest) => guest.full_name === match.bestMatch.target
      );

      if (tempGuest.group_number === -1) {
        setGroupInvite([
          {
            name: tempGuest.full_name,
            joining: tempGuest.will_attend,
          },
        ]);
        setStep(3);
      } else {
        const tempGroup = guests
          .filter((guest) => guest.group_number === tempGuest.group_number)
          .map((guest) => ({
            name: guest.full_name,
            joining: guest.will_attend,
          }));

        setGroupInvite(tempGroup);
        setStep(3);
      }
    }
  };

  const updateGroupInvite = (name, value) => {
    const newGroupInvite = groupInvite.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          joining: value,
        };
      }

      return item;
    });

    setGroupInvite(newGroupInvite);
  };

  const saveGroupInvite = () => {
    setStep(4);
  };

  const handleSubmit = async () => {
    const acceptedGuests = groupInvite
      .filter((item) => item.joining)
      .map((item) => item.name);

    const guestWhoRejected = groupInvite
      .filter((item) => !item.joining)
      .map((item) => item.name);

    const { data, error } = await supabase
      .from("invitees")
      .update({ will_attend: true, updated_at: new Date(), updated_by: ip })
      .in("full_name", acceptedGuests);

    if (guestWhoRejected.length > 0) {
      await supabase
        .from("invitees")
        .update({ will_attend: false, updated_at: new Date(), updated_by: ip })
        .in("full_name", guestWhoRejected);
    }

    if (error) {
      console.error("Error updating rows:", error);
    } else {
      setStep(5);
    }
  };

  useEffect(() => {
    if (step === 5) {
      setTimeout(() => {
        setStep(1);
      }, 3000);
    }
  }, [step]);

  return (
    <>
      <div className="text-3xl abhaya-libre-regular">RSVP</div>
      <div className="mx-12">
        {step === 1 && (
          <>
            <Reveal once={false}>
              <div className="flex flex-col items-center">
                <div className="text-l abhaya-libre-regular p-3 md:max-w-2xl md:text-xl">
                  We are delighted to inform you that a seat has been reserved
                  for you at our wedding. If you're responding for you and a
                  guest (or a family), you'll be able to RSVP for your entire
                  group.
                </div>
                <input
                  value={invitees[0]}
                  onChange={(e) => updateInvitees(e.target.value, 0)}
                  type="text"
                  className="my-4 bg-gray-50 border rounded-none border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full md:w-1/3 p-2.5 abhaya-libre-regular"
                  placeholder="Full name"
                />
                <button
                  className="abhaya-libre-regular mt-6 p-3 bg-stone-700 text-white"
                  onClick={findGuest}
                >
                  Find your invitation
                </button>
              </div>
            </Reveal>
          </>
        )}
        {step === 2 && (
          <div className="flex flex-col items-center">
            <h2 className="text-l abhaya-libre-regular md:text-xl">
              Unfortunately we cant find your name please contact +63 917 132
              2534 for more details
            </h2>
            <button
              className="bg-stone-700 text-white w-full md:w-1/3 mt-6 p-4 abhaya-libre-regular"
              onClick={() => setStep(1)}
            >
              Go back
            </button>
          </div>
        )}
        {step === 3 && (
          <div className="">
            <h2 className="text-l mb-4 abhaya-libre-regular md:text-xl">
              We’ve found you in the guest list. Please confirm your name below
              to continue with your RSVP.
            </h2>
            <div className="block md:flex flex-col justify-center items-center">
              <div>
                {groupInvite.map((item) => (
                  <div
                    key={`group-invite-${item.name}`}
                    className="flex content-center py-1"
                  >
                    <Checkbox
                      value={item.joining}
                      label={item.name}
                      onChange={updateGroupInvite}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <button
                className="bg-stone-700 text-white w-full md:w-1/3 mt-6 p-3 abhaya-libre-regular"
                disabled={invitees[0].name === ""}
                onClick={saveGroupInvite}
              >
                Continue
              </button>
              <button
                className="bg-stone-700 text-white w-full md:w-1/3 mt-6 p-3 abhaya-libre-regular"
                disabled={invitees[0].name === ""}
                onClick={() => setStep(1)}
              >
                Go back
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div>
            <h2 className="mb-4 abhaya-libre-regular text-l md:text-xl">
              Are you sure you want to submit? The following names have been
              confirmed to join our wedding event:
            </h2>
            <div className="px-4 mb-2 block md:flex flex-col justify-center items-center">
              {groupInvite
                .filter((item) => item.joining)
                .map((item) => (
                  <div key={`test-${item.name}`} className="flex py-1">
                    <li className="text-l mb-2 abhaya-libre-regular md:text-xl">
                      {item.name}
                    </li>
                  </div>
                ))}
              <h2 className="mb-4 abhaya-libre-regular flex text-l md:text-xl">
                Please confirm if everything is correct.
              </h2>
            </div>

            <div className="flex flex-col items-center">
              <button
                className="bg-stone-700 text-white w-full md:w-1/3 mt-6 p-4 abhaya-libre-regular"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="bg-stone-700 text-white w-full md:w-1/3 mt-6 p-4 abhaya-libre-regular"
                onClick={() => setStep(3)}
              >
                Go back
              </button>
            </div>
          </div>
        )}
        {step === 5 && (
          <h2 className="mb-4 abhaya-libre-regular text-l md:text-xl">
            Success, Thank you for responding!
          </h2>
        )}
      </div>
    </>
  );
};

export default Reservation;
