import React from "react";

import Reveal from "./Reveal";

const Timeline = () => {
  return (
    <>
      <Reveal>
        <div className="text-3xl mb-4 md:text-4xl">Timeline</div>
      </Reveal>
      <Reveal>
        <div className="my-2">
          <div className="text-xl uppercase md:text-2xl">Ceremony</div>
          <div className="text-l md:text-xl">3:00 PM</div>
        </div>
      </Reveal>
      <Reveal>
        <div className="my-2">
          <div className="text-xl uppercase md:text-2xl">Cocktails</div>
          <div className="text-l md:text-xl">5:00 PM</div>
        </div>
      </Reveal>
      <Reveal>
        <div className="my-2">
          <div className="text-xl uppercase md:text-2xl">Reception Program</div>
          <div className="text-l md:text-xl">6:00 PM</div>
        </div>
      </Reveal>
    </>
  );
};

export default Timeline;
