import React from "react";

const Divider = () => {
  return (
    <div className="flex justify-center m-4 block md:hidden">
      <img src="./edelweiss-watercolor.png" alt="watercolor" className="h-24" />
    </div>
  );
};

export default Divider;
